<template>
  <div class="col-lg-4 col-md-6">
      <div class="blog-item">
          <div class="popup-wrapper">
              <div class="popup-gallery">
                  <a :href="link" target="_blank">
                      <img v-if="tipo_perfil == 'Desarrollador'" :src="'/img/landing/desarrollo/'+num+'.png'" class="width-100" alt="pic">
                      <img v-else :src="'/img/landing/administrativa/'+num+'.png'" class="width-100" alt="pic">
                      <!-- <img v-else :src="'/img/landing/desarrollo/'+num+'.png'" class="width-100" alt="pic"> -->
                      <span class="eye-wrapper2"><i class="fa fa-link eye-icon"></i></span>
                  </a>
              </div>
          </div>

          <div class="blog-item-inner">

              <!-- <h3 class="blog-title"><a :href="link" target="_blank">{{ titulo }}</a></h3> -->
              <h3 class="blog-title"><a :href="link" >{{ titulo }}</a></h3>

              <a href="javascript:void(0)" class="blog-icons last"><i class="fa fa-home"></i> {{ empresa }}</a><br>
              <a href="javascript:void(0)" class="blog-icons last"><i class="fas fa-map-marker-alt"></i> {{ ciudad }}</a><br>
              <a href="javascript:void(0)" class="blog-icons last"><i class="fas fa-tag"></i> {{ rango_salarial }}</a>

              <p style="height:84px">{{ objetivo.length > 90 ? objetivo.slice(0, 90 - 1) + "…" : objetivo }}</p>

          </div>
      </div>
  </div>
</template>
<script>
export default {
  props: ['titulo','link','empresa','img','ciudad','objetivo','num','tipo_perfil','rango_salarial'],
  data() {
    return {}
  },
  methods:{ },
}
</script>
<style>
  @import '/css/landing/style.css';
</style>
