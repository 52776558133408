<template>
  <div class="row">

    <!-- Search form -->
    <div class="col-md-12">
      <br>
      <form @submit.prevent="getVacantes" class="form-inline d-flex justify-content-center md-form form-sm active-cyan-2 mt-2">
        <input class="form-control form-control-sm mr-3 w-75" id="search_string" type="text" placeholder="Buscar..."
          aria-label="Buscar..." value="">
        <button type="submit" class="btn btn-primary"><i class="fas fa-search" aria-hidden="true"></i></button>
      </form>
      <br>
    </div>

    <Vacante v-for="(v, index) in vacantes.data" v-bind:key="index"
    :link="'/vacante/'+v.link"
    :titulo="v.titulo"
    :empresa="v.trade_name"
    :ciudad="v.ciudad"
    :objetivo="v.objetivo"
    :tipo_perfil="v.tipo_perfil"
    :rango_salarial="v.rango_salarial"
    :num="Math.floor(Math.random() * (3 - 1 + 1)) + 1"
    ></Vacante>
    <div class="col-md-12">
      <br>
      <br>
      <pagination align="center" :data="vacantes" @pagination-change-page="getVacantes"></pagination>
    </div>
  </div>

</template>
<script>
import Vacante from './VacanteCardComponent'
Vue.component('pagination', require('laravel-vue-pagination'));

export default {
  components: {
    Vacante
  },
  data() {
    return {
      vacantes:{},
      old_search_string:"nada",
      actual_page:1
    }
  },
  mounted() {
    this.getVacantes();
  },
  methods:{
    getVacantes(page = 1) {
      var search_string = $("#search_string").val()
      if(this.old_search_string != search_string || this.actual_page != page ){
        axios.get('get_vacantes?page=' + page+"&search="+search_string)
          .then(response => {
            this.vacantes = response.data.vacantes;
            this.old_search_string = search_string;
            this.actual_page = this.vacantes.current_page;
          });
      }
    }
  },
}
</script>
