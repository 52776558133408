<template>
  <div class="row col-md-12">
    <Vacante v-for="(v, index) in vacantes.data" v-bind:key="index"
    :link="'/vacante/'+v.link"
    :titulo="v.titulo"
    :empresa="v.trade_name"
    :ciudad="v.ciudad"
    :objetivo="v.objetivo"
    :tipo_perfil="v.tipo_perfil"
    :rango_salarial="v.rango_salarial"
    :num="Math.floor(Math.random() * (3 - 1 + 1)) + 1"
    ></Vacante>
  </div>
</template>
<script>
import Vacante from './VacanteCardComponent'

export default {
  components: {
    Vacante
  },
  data() {
    return {
      vacantes:{}
    }
  },
  mounted() {
    this.getVacantes();
  },
  methods:{
    getVacantes(page = 1) {
      axios.get('get_vacantes?page=' + page)
        .then(response => {
          this.vacantes = response.data.vacantes;
          this.vacantes.data = this.vacantes.data.slice(0, 3)
        });
    }
  },
}
</script>
