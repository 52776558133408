require('./bootstrap');
import Swal from 'sweetalert2';
window.Swal = Swal;

window.Vue = require('vue');

Vue.component('vacantes-home', require('./components/VacantesHomeComponent.vue').default);
Vue.component('vacantes-paginated', require('./components/VacantesPaginateComponent.vue').default);

const app = new Vue({
    el: '#app'
});
